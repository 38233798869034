<template>
	<div class="chat-group-side">
		<div v-show="!group.quit" class="group-side-search">
			<el-input placeholder="搜索群成员" v-model="searchText">
				<i class="el-icon-search el-input__icon" slot="prefix"> </i>
			</el-input>
		</div>
		<el-scrollbar class="group-side-scrollbar">
			<div v-show="!group.quit" class="group-side-member-list">
				<div class="group-side-invite" v-if="isOwner">
					<div class="invite-member-btn" title="邀请好友进群" @click="showAddGroupMember=true">
						<i class="el-icon-plus"></i>
					</div>
					<div class="invite-member-text">邀请</div>
					<add-group-member :visible="showAddGroupMember" :groupId="group.id" :members="groupMembers" @reload="$emit('reload')"
					 @close="showAddGroupMember=false"></add-group-member>
				</div>
				<div v-for="(member) in groupMembers" :key="member.id">
					<group-member class="group-side-member" v-show="!member.quit && member.showNickName.includes(searchText)" :member="member"
					 :showDel="false"></group-member>
				</div>
			</div>
			<el-divider v-if="!group.quit" content-position="center"></el-divider>
			<el-form labelPosition="top" class="group-side-form" :model="group">
				<el-form-item label="群名称">
					<span v-if="!editing">{{ group.name }}</span>
					<el-input v-else v-model="group.name" disabled maxlength="20"></el-input>
				</el-form-item>
				<el-form-item label="群主" v-if="!editing">
					<span>{{ ownerName }}</span>
					<!-- TODO：转让群主 -->
					<!-- <el-input v-else :value="ownerName" disabled></el-input> -->
				</el-form-item>
				<el-form-item label="群公告">
					<span v-if="!editing">{{ group.notice ? group.notice : '暂无群公告' }}</span>
					<el-input v-else v-model="group.notice" disabled type="textarea" maxlength="1024" placeholder="群主未设置"></el-input>
				</el-form-item>
				<el-form-item label="备注" v-if="group.remarkGroupName || editing">
					<span v-if="!editing">{{ group.remarkGroupName }}</span>
					<el-input v-else v-model="group.remarkGroupName" :disabled="!editing" :placeholder="group.name" maxlength="20"></el-input>
				</el-form-item>
				<el-form-item label="我在本群的名称">
					<span v-if="!editing">{{ group.remarkNickName ? group.remarkNickName : $store.state.userStore.userInfo.nickName }}</span>
					<el-input v-else v-model="group.remarkNickName" :disabled="!editing"  maxlength="20"
						:placeholder="$store.state.userStore.userInfo.nickName" ></el-input>
				</el-form-item>
				<div v-show="!group.quit" class="btn-group">
					<el-button v-if="editing" type="" @click="editing=!editing">取消</el-button>
					<el-button v-if="editing" type="success" @click="onSaveGroup()">提交</el-button>
					<el-button v-if="!editing" type="primary" @click="editing=!editing">编辑</el-button>
					<el-button type="danger" v-if="!isOwner && !editing" @click="onQuit()">退出群</el-button>
					<el-button type="warning" v-if="isOwner && !editing" @click="onTransferOwner()">转让群</el-button>
				</div>
			</el-form>
		</el-scrollbar>

		<group-member-selector ref="memberSel" :groupId="group.id" @complete="onTransferOwnerOk" />
	</div>
</template>

<script>
	import AddGroupMember from '../group/AddGroupMember.vue';
	import GroupMemberSelector from "../group/GroupMemberSelector.vue";
	import GroupMember from '../group/GroupMember.vue';

	export default {
		name: "chatGroupSide",
		components: {
			AddGroupMember,
			GroupMemberSelector,
			GroupMember
		},
		data() {
			return {
				searchText: "",
				editing: false,
				showAddGroupMember: false
			}
		},
		props: {
			group: {
				type: Object
			},
			groupMembers: {
				type: Array
			}
		},
		methods: {
			onClose() {
				this.$emit('close');
			},
			loadGroupMembers() {
				this.$http({
					url: `/group/members/${this.group.id}`,
					method: "get"
				}).then((members) => {
					this.groupMembers = members;
				})
			},
			onSaveGroup() {
				let vo = this.group;
				this.$http({
					url: "/group/modify",
					method: "put",
					data: vo
				}).then((group) => {
					this.$store.commit("updateGroup", group);
					this.$emit('reload');
					this.$message.success("修改成功");
				})
			},
			onQuit() {
				this.$confirm('退出群后将不再接受群里的消息，确认退出吗？', '确认退出?', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http({
						url: `/group/quit/${this.group.id}`,
						method: 'delete'
					}).then(() => {
						this.$store.commit("removeGroup", this.group.id);
						this.$store.commit("activeGroup", -1);
						this.$store.commit("removeGroupChat", this.group.id);
					});
				})
			},

			// 转让群主：打开群成员选择器
			onTransferOwner(){
				let ids = [this.mine.id];
				let maxChannel = 1;
				this.$refs.memberSel.open(maxChannel, [], ids);
			},
			onTransferOwnerOk(members){
				if (members.length === 0) {
					this.$message.error("您没有选择要转让的成员");
					return;
				}else if (members.length > 1) {
					this.$message.error("您只能选择一个要转让的成员");
					return;
				}
				
				let vo = {...this.group, ownerId: members[0].userId};
				this.$http({
					url: "/group/transferOwner",
					method: "put",
					data: vo
				}).then((group) => {
					this.$store.commit("updateGroup", vo);
					this.$emit('reload');
					this.$message.success("转让成功");
				})

			},
		},
		computed: {
			mine() {
				return this.$store.state.userStore.userInfo;
			},
			ownerName() {
				let member = this.groupMembers.find((m) => m.userId == this.group.ownerId);
				return member && member.showNickName;
			},
			isOwner() {
				return this.group.ownerId == this.$store.state.userStore.userInfo.id;
			}
			
		}
	}
</script>

<style lang="scss">
	.chat-group-side {
		position: relative;
		
		.group-side-search {
			padding: 8px;
		}

		.group-side-member-list {
			padding: 10px;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			font-size: 16px;
			text-align: center;

			.group-side-member {
				margin-left: 15px;
			}

			.group-side-invite {
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 50px;
				margin-left: 15px;

				.invite-member-btn {
					width: 100%;
					height: 50px;
					line-height: 50px;
					border: #cccccc solid 1px;
					font-size: 25px;
					cursor: pointer;
					box-sizing: border-box;

					&:hover {
						border: #aaaaaa solid 1px;
					}
				}

				.invite-member-text {
					font-size: 16px;
					text-align: center;
					width: 100%;
					height: 30px;
					line-height: 30px;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden
				}
			}
		}

		.group-side-form {
			text-align: left;
			padding: 10px;
			height: 30%;

			.el-form-item {
				margin-bottom: 12px;

				.el-form-item__label {
					padding: 0;
					line-height: 30px;
				}

				.el-textarea__inner {
					min-height: 100px !important;
				}
			}

			.btn-group {
				text-align: center;
			}
		}

	}
</style>
