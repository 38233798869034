<template>
	<el-drawer title="发送链接(测试)" size="500px"  :visible.sync="visible" direction="rtl" :before-close="onClose">
		<div class="chat-history">
			<el-form :model="cardForm" status-icon :rules="rules" ref="cardForm" label-width="100px">
				<el-form-item label="标题" prop="title">
					<el-input v-model="cardForm.title" placeholder="填写信息卡的标题"></el-input>
				</el-form-item>
				<el-form-item label="描述" prop="describe">
					<el-input type="textarea" v-model="cardForm.describe" placeholder="填写信息卡的描述"></el-input>
				</el-form-item>
				<el-form-item label="图片" prop="image">
					<el-input type="textarea" v-model="cardForm.image" placeholder="填写配图的url"></el-input>
				</el-form-item>
				<el-form-item label="链接" prop="url">
					<el-input type="textarea" v-model="cardForm.url" placeholder="填写链接url"></el-input>
				</el-form-item>
				<el-form-item label="来源名称" prop="srcTitle">
					<el-input v-model="cardForm.srcTitle" placeholder="填写信息来源名称"></el-input>
				</el-form-item>
				<el-form-item label="来源logo" prop="srcLogo">
					<el-input type="textarea" v-model="cardForm.srcLogo" placeholder="填写信息来源logo的url"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('cardForm')">发送</el-button>
					<el-button @click="resetForm('cardForm')">清空</el-button>
				</el-form-item>
			</el-form>
		</div>
	</el-drawer>
</template>

<script>
	export default {
		name: 'chatCard',
		props: {
			visible: {
				type: Boolean
			},
			chat: {
				type: Object
			},
			friend: {
				type: Object
			},
			group: {
				type: Object
			},
			groupMembers: {
				type: Array,
			}
		},
		data() {
			return {
				cardForm: {},
				rules: {
					title: [
						{ required: true, message: '请填写标题', trigger: 'blur' },
					],
				}
			}
		},
		methods: {
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			onClose() {
				this.$refs['cardForm'].resetFields();
				this.$emit('close');
			},
			submitForm(){
				this.$refs['cardForm'].validate((valid) => {
					if (valid) {
						let cardMsg = {
							type: 'card',
							content: JSON.stringify(this.cardForm),
						}
						this.$emit('send', [cardMsg]);
						this.onClose();
					}
				});
			}
		},
		computed: {
			mine() {
				return this.$store.state.userStore.userInfo;
			},
		},
	}
</script>

<style lang="scss">
	.chat-history {
		display: flex;
		height: 100%;
		
		.chat-history-scrollbar {
			flex: 1;
			.el-scrollbar__thumb {
			    background-color: #555555;
			}
			ul {
				padding: 20px;

				li {
					list-style-type: none;
				}
			}
		}
	}
</style>
