<template>
	<el-dialog title="转发选择" :visible.sync="isShow" width="50%">
		<div class="forward-item-selector">
			<div class="left-box">
				<el-input placeholder="搜索" v-model="searchText" class="search-input">
					<i class="el-icon-search el-input__icon" slot="suffix"> </i>
				</el-input>
				<el-scrollbar style="height:400px;">
					<!-- 最近聊天 -->
					<div class="panel chats" v-if="!searchText">
						<div class="panel-title">最近联系</div>
						<div class="chat-item" v-for="(chat,index) in chatStore" :key="index" @click="onSelected(chat)">
							<div class="head-avatar">
								<head-image :url="chat.headImage" :name="chat.showName" :size="30"
									:id="chat.type=='PRIVATE' ? chat.targetId : 0"
									:online="chat.type=='PRIVATE' ? chat.online : null"
								/>
							</div>
							<div class="chat-name">
								<div class="chat-tag" v-if="chat.type=='GROUP'">
									<el-tag size="mini" >群</el-tag>
								</div>
								<div class="chat-name-text" :title="chat.showName">{{chat.showName}}</div>
							</div>
							<div class="check-box">
								<el-checkbox v-model="chat.checked" 
									@change="onChange(chat)"
								></el-checkbox>
							</div>
						</div>
					</div>
					<!-- 好友 -->
					<div class="friend" v-if="friendStore.length">
						<el-divider v-if="!searchText" class="divider-style" />
						<div class="panel-title">联系人</div>
						<div class="chat-item" v-for="(friend,index) in friendStore" :key="index" @click="onSelected(friend)">
							<div class="head-avatar">
								<head-image :size="30" :name="friend.showName" :url="friend.headImage" :online="friend.online"></head-image>
							</div>
							<div class="item-name">{{friend.showName}}</div>
							<div class="check-box">
								<el-checkbox v-model="friend.checked" 
									@change="onChange(friend)"
								></el-checkbox>
							</div>
						</div>
					</div>
					<!-- 群组 -->
					<div class="group" v-if="groupStore.length">
						<el-divider class="divider-style" />
						<div class="panel-title">群组</div>
						<div class="chat-item" v-for="(group,index) in groupStore" :key="index" @click="onSelected(group)">
							<div class="head-avatar">
								<head-image :size="30" :name="group.showName" :url="group.headImage"> </head-image>
							</div>
							<div class="item-name">{{group.showName}}</div>
							<div class="check-box">
								<el-checkbox v-model="group.checked" 
									@change="onChange(group)"
								></el-checkbox>
							</div>
						</div>
					</div>
				</el-scrollbar>
			</div>
			<div class="arrow el-icon-d-arrow-right"></div>
			<div class="right-box">
				<div class="select-tip"> 已勾选{{members.length}}个对象</div>
				<div class="checked-member-list">
					<el-scrollbar :style="{'max-height': chatInfo.type == $enums.MESSAGE_TYPE.IMAGE ? '222px' : '298px'}">
						<div class="chat-item member-item" v-for="(item,index) in members" :key="index">
							<div class="head-avatar">
								<head-image :url="item.headImage" :name="item.showName" :size="30"
									:id="item.type=='PRIVATE' ? item.targetId : 0"
									:online="item.type=='PRIVATE' ? item.online : null"
								/>
							</div>
							<div class="chat-name">
								<div class="chat-tag" v-if="item.type=='GROUP'">
									<el-tag size="mini" >群</el-tag>
								</div>
								<div class="chat-name-text" :title="item.showName">{{item.showName}}</div>
							</div>
							<div class="mem-remove">
								<i class="el-icon-error" @click="removeMembers(item,true)" />
							</div>
						</div>
					</el-scrollbar>
				</div>
				<div class="forward-chat">
					<el-divider class="divider-style" />
					<div v-if="chatInfo.type == $enums.MESSAGE_TYPE.IMAGE">
						<el-image class="forward-image"
							:src="chatInfo.content"
							fit="contain" 
						>
							<div slot="error" class="image-slot">
								<i class="el-icon-picture-outline"></i>
							</div>
						</el-image>
					</div>
					<div v-else class="forward-text">
						{{chatInfo.typeTitle}}
						{{chatInfo.content}}
					</div>
					<div class="forward-msg">
						<el-input v-model="subMsg" placeholder="留言" />
					</div>
				</div>
			</div>
		</div>
		<span slot="footer" class="dialog-footer">
			<el-button @click="close()">取 消</el-button>
			<el-button type="primary" :loading="isSending" :disabled="members.length == 0" @click="onOk()">发 送</el-button>
		</span>
	</el-dialog>
</template>

<script>
	import HeadImage from '../common/HeadImage.vue';

	export default {
		name: "ChatForwardSelector",
		components: {
			HeadImage
		},
		data() {
			return {
				isShow: false,
				searchText: "",
				subMsg: "",
				// 选择数量上限
				maxSize: 100,
				// 选中的对象
				members: [],
				// 要转发的会话
				forwardChat: {},
				chatInfo: {},
				chatStore: [],
				friendStore: [],
				groupStore: [],
				lockMessage: false, // 是否锁定发送，
				reqQueue: [],	// 发送队列
				isSending : false,
			}
		},
		watch: {
		    searchText(val) {
				this.getFriendStore();
				this.getGroupStore();
		    }
		},
		methods: {
			getChatStore() {
				this.chatStore = this.$store.state.chatStore.chats.map(item => {
					return {
						oType: 'CHAT', 
						checked: false, 
						headImage: item.headImage,
						showName: item.showName,
						type: item.type,
						targetId: item.targetId,
						online: item.type == "PRIVATE" ? item.online : null
					}
				})
			},
			getFriendStore() {
				let friends = this.$store.state.friendStore.friends;
				if(this.searchText && this.searchText != ""){
					// 搜索
					friends = friends.filter(item => item.nickName.indexOf(this.searchText) != -1)
				}
				this.friendStore = friends.map(item => {
					return {
						oType: this.$enums.CHAT_TYPE.PRIVATE, 
						type: this.$enums.CHAT_TYPE.PRIVATE,
						checked: this.members.findIndex(member => member.type == this.$enums.CHAT_TYPE.PRIVATE && member.targetId == item.id) != -1, 
						headImage: item.headImage,
						showName: item.nickName,
						targetId: item.id,
						online: item.online
					}
				});
			},
			getGroupStore() {
				let groups = this.$store.state.groupStore.groups;
				if(this.searchText && this.searchText != ""){
					// 搜索
					groups = groups.filter(item => item.showGroupName.indexOf(this.searchText) != -1)
				}
				this.groupStore = groups.map(item => {
					return {
						oType: this.$enums.CHAT_TYPE.GROUP, 
						type: this.$enums.CHAT_TYPE.GROUP,
						checked: this.members.findIndex(member => member.type == this.$enums.CHAT_TYPE.GROUP && member.targetId == item.id) != -1, 
						headImage: item.headImage,
						showName: item.showGroupName,
						targetId: item.id
					}
				});
			},
			open(chatInfo,maxSize) {
				this.forwardChat = chatInfo;
				this.madeForwardText(chatInfo);
				this.maxSize = maxSize || 100;
				this.members = [];
				this.searchText = "";
				this.subMsg = "";
				this.isShow = true;

				this.getChatStore();
				this.getFriendStore();
				this.getGroupStore();
			},
			madeForwardText(msgInfo){
				let tmpChatInfo = {
					type: msgInfo.type, 
					typeTitle: "",
					content: msgInfo.content
				};
				// 插入新的数据
				if (msgInfo.type == this.$enums.MESSAGE_TYPE.IMAGE) {
					let msgObj = JSON.parse(msgInfo.content)
					tmpChatInfo.typeTitle = "[图片]";
					tmpChatInfo.content = msgObj.thumbUrl;
				} else if (msgInfo.type == this.$enums.MESSAGE_TYPE.FILE) {
					let msgObj = JSON.parse(msgInfo.content)
					tmpChatInfo.typeTitle = "[文件]";
					tmpChatInfo.content = msgObj.name;
				} else if (msgInfo.type == this.$enums.MESSAGE_TYPE.AUDIO) {
					let msgObj = JSON.parse(msgInfo.content)
					tmpChatInfo.typeTitle = "[语音]";
					tmpChatInfo.content = "时长 ${msgObj.duration} s";
				} else if (msgInfo.type == this.$enums.MESSAGE_TYPE.CARD_BASE) {
					let msgObj = JSON.parse(msgInfo.content)
					tmpChatInfo.typeTitle = "[链接]";
					tmpChatInfo.content = msgObj.title;
				} else if (msgInfo.type == this.$enums.MESSAGE_TYPE.TEXT) {
					tmpChatInfo.content = msgInfo.content;
				}
				this.chatInfo = tmpChatInfo;
			},
			onSelected(m) {
				if (this.members.length > this.maxSize) {
					this.$message.error(`最多选择${this.maxSize}个转发对象`)
					m.checked = false;
				}else if(!m.checked){
					m.checked = true;
					this.pushMembers(m);
				}else{
					m.checked = false;
					// 去除自己
					this.removeMembers(m,true)
				}
			},
			onChange(m) {
				m.checked = !m.checked;
				if (m.checked && this.members.length > this.maxSize) {
					this.$message.error(`最多选择${this.maxSize}位成员`)
					m.checked = false;
				}else if(m.checked){
					this.pushMembers(m);
				}else{
					// 去除自己
					this.removeMembers(m,true)
				}
			},
			// 添加选中成员
			pushMembers(m){
				this.members.push(m);
				this.syncCheckedStore(m,true);
			},
			// 移除选中成员
			removeMembers(m,isUnchecked){
				this.members = this.members.filter(item => item.type != m.type || item.targetId != m.targetId)
				// 取消勾选
				if(isUnchecked){
					if(m.oType == 'CHAT'){
						this.chatStore.map(item => {
							if(item.type == m.type && item.targetId == m.targetId){
								item.checked = false;
							}
						})
					}else if(m.oType == 'PRIVATE'){
						this.friendStore.map(item => {
							if(item.targetId == m.targetId){
								item.checked = false;
							}
						})
					}else if(m.oType == 'GROUP'){
						this.groupStore.map(item => {
							if(item.targetId == m.targetId){
								item.checked = false;
							}
						})
					}
					this.syncCheckedStore(m,false);
				}
			},
			// 同步其他选中状态
			syncCheckedStore(m,checked) {
			    if(m.oType == 'CHAT'){
					if(m.type=='PRIVATE'){
						this.friendStore.map(item => {
							if(item.targetId == m.targetId){
								item.checked = checked;
							}
						})
					}else if(m.type=='GROUP'){
						this.groupStore.map(item => {
							if(item.targetId == m.targetId){
								item.checked = checked;
							}
						})
					}
				}else if(m.oType == 'PRIVATE'){
					this.chatStore.map(item => {
						if(item.type=='PRIVATE' && item.targetId == m.targetId)
							item.checked = checked;
					})
				}else if(m.oType == 'GROUP'){
					this.chatStore.map(item => {
						if(item.type=='GROUP' && item.targetId == m.targetId)
							item.checked = checked;
					})
				}
			},
			onOk(){
				this.members.forEach(m => {
					this.sendForwardMessage(
						this.forwardChat.content, 
						this.forwardChat.type,
						m.targetId, 
						[], 
						m.type == this.$enums.CHAT_TYPE.GROUP
					);
					if(this.subMsg){
						this.sendForwardMessage(
							this.subMsg, 
							this.$enums.MESSAGE_TYPE.TEXT,
							m.targetId, 
							[], 
							m.type == this.$enums.CHAT_TYPE.GROUP,
						);
					}
				})
			},
			close() {
				this.isShow = false;
			},
			/***** 转发消息
			 * msgInfo: 转发的消息
			 * sendType: 转发的消息类型
			 * targetId: 目标id
			 * atUserIds: 被@人员列表
			 * isGroup: 是否是群聊
			*/
			sendForwardMessage(sendText,sendType,targetId,atUserIds,isGroup) {
				return new Promise((resolve,reject)=>{
					if (!sendText.trim()) {
						reject();
					}
					let msgInfo = {
						content: sendText,
						type: sendType,
					}
					// 填充对方id
					if(isGroup){
						msgInfo.targetType = this.$enums.CHAT_TYPE.GROUP;
						msgInfo.groupId = targetId;
						msgInfo.atUserIds = atUserIds;
						msgInfo.receipt = false;
					}else{
						msgInfo.targetType = this.$enums.CHAT_TYPE.PRIVATE;
						msgInfo.recvId = targetId;
					}
					this.lockMessage = true;
					this.sendMessageRequest(msgInfo).then((m) => {
						m.selfSend = true;
						this.$store.commit("insertMessage", m);
						// 会话置顶
						this.moveChatToTop(msgInfo);
					}).finally(() => {
						// 解除锁定
						// this.scrollToBottom();
						this.isReceipt = false;
						resolve();
					});
				});
			},
			// 发送消息请求
			sendMessageRequest(msgInfo){
				return  new Promise((resolve,reject)=>{
					// 请求入队列，防止请求"后发先至"，导致消息错序
					this.reqQueue.push({msgInfo,resolve,reject});
					// if(subMsg){
					// 	let subMsgInfo = {
					// 		...msgInfo,
					// 		content:subMsg,
					// 		type: this.$enums.MESSAGE_TYPE.TEXT
					// 	}
					// 	this.reqQueue.push({msgInfo: subMsgInfo,resolve,reject});
					// }
					this.processReqQueue();
				})
			},
			// 处理队列
			processReqQueue(){
				if (this.reqQueue.length && !this.isSending) {
					this.isSending = true;
					const reqData = this.reqQueue.shift();
					this.$http({
						url: `/message/${reqData.msgInfo.targetType.toLowerCase()}/send`,
						method: 'post',
						data: reqData.msgInfo
					}).then((res)=>{
						reqData.resolve(res)
					}).catch((e)=>{
						reqData.reject(e)
					}).finally(()=>{
						this.isSending = false;
						// 发送下一条请求
						this.processReqQueue();
					})
				}else if(!this.reqQueue.length && !this.isSending){
					this.isShow = false;
				}
			},
			moveChatToTop(msgInfo) {
				let chat = this.$store.state.chatStore.chats.find(item => 
					item.type == msgInfo.targetType
					&& [msgInfo.recvId,msgInfo.groupId].includes(item.targetId)
				)
				let chatIdx = this.$store.getters.findChatIdx(chat);
				this.$store.commit("moveTop", chatIdx);
			},
		}
	}
</script>

<style lang="scss">
	.forward-item-selector {
		display: flex;
		margin: 5px;


		.left-box {
			width: 48%;
			border: #587FF0 solid 1px;
			border-radius: 5px;
			overflow: hidden;
			.search-input{
				width: 95%;
				margin-top: 5px;
				.el-input__inner {
					height: 35px;
					line-height: 35px;
					background-color: #EBEEF5;
					&:focus{
						background-color: #FFF;
					}
				}
			}
		}


		.arrow {
			display: flex;
			align-items: center;
			font-size: 20px;
			padding: 5px;
			font-weight: 600;
			color: #687Ff0;
		}

		.right-box {
			width: 48%;
			border: #587FF0 solid 1px;
			border-radius: 5px;
			flex-direction: column;
			display: flex;

			.select-tip {
				text-align: left;
				height: 40px;
				line-height: 40px;
				text-indent: 5px;
				flex: 0 0 auto; /* 头部的高度将根据内容自适应 */
			}

			.checked-member-list {
				width: 100%;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				flex: 1 0 auto; /* 内容区域将自动填充剩余空间 */
				.member-item{
					width: 90%
				}
			}
			.forward-chat{
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				flex: 0 0 auto; /* 脚部的高度将根据内容自适应 */
				background-color: #fff;
				.forward-image {
					min-width: 100px;
					min-height: 100px;
					max-width: 200px;
					max-height: 200px;
					border: 1px solid #ccc;
					border-radius: 5px;
				}
				.forward-text{
					padding-top: 5px;
					height: 30px;
					width: 93%;
					text-align: left;
					color: #909399;
					// display: flex;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
				.forward-msg{
					width: 90%;
					margin-bottom: 10px;
					.el-input__inner {
						height: 35px;
						line-height: 35px;
						background-color: #EBEEF5;
						&:focus{
							background-color: #FFF;
						}
					}
				}
			}
		}
		.divider-style{
			margin: 10px;
			width: 93%;
		}
		.panel{
			width: 90%;
		}
		// 栏目标题
		.panel-title{
			color: #909399;
			width: 100%;
			margin: 10px;
			text-align: left;
		}
		.head-avatar {
			width: 35px;
			height: 30px;
		}
		.chat-item {
			height: 30px;
			width: 100%;
			display: flex;
			margin-left: 5px;
			margin-bottom: 1px;
			position: relative;
			padding: 5px 10px;
			align-items: center;
			background-color: white;
			white-space: nowrap;
			color: black;
			cursor: pointer;

			&:hover {
				background-color: #F8FAFF;
			}

			&.active {
				background-color: #F4F9FF;
			}
		}

		.item-name {
			padding-left: 5px;
			height: 25px;
			line-height: 25px;
			width: 69%;
			text-align: left;
			white-space: nowrap;
			overflow: hidden;
			font-size: 15px;
			// font-weight: 600;
		}
		.chat-name {
			display: flex;
			line-height: 25px;
			height: 25px;
			width: 80%;
			padding-left: 5px;
			text-align: left;

			.chat-tag {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: 1px;
			}

			.chat-name-text {
				flex: 1;
				font-size: 15px;
				// font-weight: 600;
				white-space: nowrap;
				overflow: hidden;
			}
		}
		.check-box, .mem-remove{
			display: flex;
			float: right;
		}
		.mem-remove{
		    i{
				color: #C0C4CC;
				font-size: 16px;
				&:hover {
					color: #909399;
				}
			}
		}
	}
</style>